import { useEffect, useState } from 'react';
import { Engine } from 'react-babylonjs';
import Cyberbox from '../scenes/Cyberbox';
import { CanvasWrapper } from './RenderingCanvas.css';
import LoadingScreen from '../components/LoadingScreen';
// import Snowfall from 'react-snowfall';
// import { snowflake1 } from '../scenes/images';
// import PoweredLogo from '../components/PoweredLogo';

export default function RenderingCanvas() {
  const [loader, setLoader] = useState<boolean>(true);
  const [loadingOpacity, setLoadingOpacity] = useState<number>(1);

  useEffect(() => {
    if (loadingOpacity === 0) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [loadingOpacity]);

  return (
    <CanvasWrapper>
      {loader && <LoadingScreen opacity={loadingOpacity} />}
      {/* {!loader && <Snowfall color="white" snowflakeCount={350} radius={[1, 20]} images={[snowflake1]} />} */}
      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Cyberbox setLoadingOpacity={setLoadingOpacity} />
      </Engine>
      {/* <PoweredLogo /> */}
    </CanvasWrapper>
  );
}
